
        /**
        * Generated by script tools/bump.version.js
        *
        * See docs/VERSION.md
        *
        * To update version:
        * 1. Make sure repo is clean ( everything committed )
        * 2. Edit the ./spout-version.json file in root
        * 3. Run yarn bump.version
        *
        */
        import { BuildVersionElectron, BuildVersionUi } from './spout-version.model';

        /**
         * Mac, PC
         */
        export const baseElectronVersion: BuildVersionElectron = {
          year: 2022,
          month: '6',
          electronMajorVersion: 18,
          electronVersion: '18.1.0',
          buildNumber: 1,
        };

        /**
         * UI
         */
        export const ui: BuildVersionUi = {
          year: 2022,
          month: '7',
          angularVersion: 14,
          buildNumber: 51,
        };

        /**
        * Build Type
        */
        export const releaseType = "beta"
  