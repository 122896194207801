import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatLegacyCheckboxChange as MatCheckboxChange} from '@angular/material/legacy-checkbox';
import {EmailPassword} from '@spout/global-web/models';
import {BehaviorSubject} from 'rxjs';
import {passwordsNotMatch} from '../logn.validators';
import {
  CustomFirestoreService,
  StripePaymentSelectionService
} from '@spout/global-web/data-access';
import {Router} from '@angular/router';

@Component({
  selector: 'spt-signup-email',
  templateUrl: './signup-email.component.html',
  styleUrls: ['./signup-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupEmailComponent {
  signUpForm: UntypedFormGroup;

  inputType = 'password';

  @Output() emailPassword: EventEmitter<EmailPassword> =
    new EventEmitter<EmailPassword>();
  @Output() dirtyAndValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    public payment: StripePaymentSelectionService,
    private sptFirebase: CustomFirestoreService,
    private router: Router,
    private _cd: ChangeDetectorRef
  ) {
    this.signUpForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        spoutPromo: ['GETSPOUTNOW', []],
        confirmPassword: ['', [Validators.required]]
      },
      {validators: passwordsNotMatch}
    );

    this.signUpForm.valueChanges.subscribe(() => {
      this.dirtyAndValid.next(this.signUpForm.dirty && this.signUpForm.valid);
      // this.cd.markForCheck();
    });
  }
  applyPromo() {
    this.emailPassword.next({...this.signUpForm.value, open_promo: true});
  }

  ngAfterViewInit(): void {}
  showPassword(event: MatCheckboxChange) {
    this.inputType = event.checked ? 'text' : 'password';
    this._cd.detectChanges();
  }

  submitEmailPassword() {
    if (this.signUpForm.valid) {
      this.emailPassword.next({...this.signUpForm.value, open_promo: false});
    }
  }
}
