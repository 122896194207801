
export  class AlgoliaSearchKeys {
	appId:string;
	apiKey:string;

	constructor(appId:string,apiKey:string) {
	this.appId = appId;
	this.apiKey = apiKey;
	}
}

let algoliaConfigDev: AlgoliaSearchKeys = {
	appId : "appId",
	apiKey : "apiKey"
};

export { algoliaConfigDev};  