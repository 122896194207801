import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {distinctUntilJsonChanged} from '@spout/global-web/fns';
import {ACCOUNT_ROLE} from '@spout/global-web/models';
import {pipe} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {Coupon, Invoice, selectAllInvoices} from '../+invoices';
import {getCouponCouponBySubscriptionID} from '../+invoices/invoice.fns';
import {hasActiveIOSSubscription} from '../../+in-app-purchase';
import {
  calculateUnitPriceFromDiscount,
  getRoleFromSubscription
} from './subscription.fns';
import {
  StripeCoupon,
  Subscription,
  SubscriptionItem,
  SubscriptionProductAndPrice,
  SubscriptionStatus,
  SubscriptionUIdisplay,
  SubscriptionUIItem,
  Trial
} from './subscription.model';
import * as SubscriptionReducer from './subscription.reducer';
import {SubscriptionState} from './subscription.reducer';

export const selectSubscriptionState =
  createFeatureSelector<SubscriptionReducer.SubscriptionState>(
    SubscriptionReducer.subscriptionFeatureKey
  );

export const selectSubscriptionsIsRetrievedFromFirestore = createSelector(
  selectSubscriptionState,
  (state: SubscriptionState): boolean => state.isRetrievedFromFirestore
);

export const selectSubscriptionsIsRetrievedFromFirestore$ = pipe(
  select(selectSubscriptionsIsRetrievedFromFirestore),
  filter((isRetrievedFromFirestore: boolean) => isRetrievedFromFirestore),
  distinctUntilChanged()
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  SubscriptionReducer.subscriptionAdapter.getSelectors();

export const selectAllSubscriptions = createSelector(
  selectSubscriptionState,
  (state: SubscriptionReducer.SubscriptionState) => selectAll(state)
);
export const selectSubscriptionEntities = createSelector(
  selectSubscriptionState,
  (state: SubscriptionReducer.SubscriptionState) => selectEntities(state)
);
export const selectSubscriptionIds = createSelector(
  selectSubscriptionState,
  (state: SubscriptionReducer.SubscriptionState) => selectIds(state)
);
export const selectSubscriptionTotal = createSelector(
  selectSubscriptionState,
  (state: SubscriptionReducer.SubscriptionState) => selectTotal(state)
);

export const selectTrialDays = createSelector(
  selectSubscriptionState,
  (state: SubscriptionReducer.SubscriptionState): number => state.trialDays
);

export const selectStripeCoupon = createSelector(
  selectSubscriptionState,
  (state: SubscriptionReducer.SubscriptionState): StripeCoupon => ({
    stripeCoupon: state.stripeCoupon,
    stripeCouponMessage: state.stripeCouponMessage
  })
);

export const selectActiveSubscriptions = createSelector(
  selectAllSubscriptions,
  (s: Subscription[]): Subscription[] => {
    return s.filter(
      (_s: Subscription) =>
        _s.status === SubscriptionStatus.active ||
        _s.status === SubscriptionStatus.trialing
    );
  }
);

export const selectMetaDataRoleFromSubscription = createSelector(
  selectActiveSubscriptions,
  (s: Subscription[]) => getRoleFromSubscription(s)
);

// Subscription that is a plan
export const selectHasActiveStripePlanSubscription = createSelector(
  selectActiveSubscriptions,
  (subs: Subscription[]): boolean => {
    const activeSubscriptions = subs.filter(
      (s: Subscription) => s.product !== undefined && s.product !== null
    );

    return activeSubscriptions.length > 0;
  }
);

// Any subscription is active
export const selectHasActiveStripeSubscription = createSelector(
  selectActiveSubscriptions,
  (subs: Subscription[]): boolean => {
    return subs.length > 0;
  }
);

export const selectHasActiveSubscription = createSelector(
  selectHasActiveStripeSubscription,
  hasActiveIOSSubscription,
  (hasStripe: boolean, hasIOS: boolean): boolean => {
    return hasStripe || hasIOS;
  }
);

export const selectHasActiveSubscription$ = pipe(
  select(selectHasActiveSubscription),
  filter((hasActiveSubscription: boolean) => hasActiveSubscription),
  distinctUntilChanged()
);

/**
 * There is only one subscription for this storageProduct
 */
export const selectActiveSubscription = createSelector(
  selectActiveSubscriptions,
  (s: Subscription[]): Subscription | null => {
    return s.reduce((a: Subscription | null, i: Subscription) => {
      if (!a) {
        return i;
      }
      return a;
    }, null);
  }
);

export const selectActiveSubscriptionItems = createSelector(
  selectActiveSubscriptions,
  (s: Subscription[]): SubscriptionItem[] =>
    s.reduce((a: SubscriptionItem[], i: Subscription) => [...a, ...i.items], [])
);

export const subscriptionProductsAndPrices = createSelector(
  selectActiveSubscriptionItems,
  (s: SubscriptionItem[]): SubscriptionProductAndPrice =>
    s.reduce(
      (a: SubscriptionProductAndPrice, i: SubscriptionItem) => {
        a.priceEntities[i.price.id] = i.price;
        a.productEntities[i.price.product.id] = i.price.product;
        return a;
      },
      {
        priceEntities: {},
        productEntities: {}
      }
    )
);

// export const selectSubscriptionAccountRole = createSelector(
//   selectActiveSubscriptions,
//   (s: Subscription[]) => {
//     return s.reduce((role: number, s: Subscription) => {
//       if (s.metadata.accountRole)
//     }, ACCOUNT_ROLE.PERSONAL);
//   }
// )

export const selectActiveSubscriptionsUIDisplay = createSelector(
  selectActiveSubscriptions,
  selectAllInvoices,
  (subs: Subscription[], invoices: Invoice[]): SubscriptionUIdisplay | null => {
    return subs.reduce((a: SubscriptionUIdisplay | null, s: Subscription) => {
      console.log('selectActiveSubscriptionsUIDisplay');
      if (!a) {
        const coupon: Coupon | null = getCouponCouponBySubscriptionID(
          invoices,
          s.id
        );

        var result = <SubscriptionUIdisplay>{
          subscription: s,
          subscriptionID: s.id,
          hasCoupon: coupon !== null,
          coupon: coupon,
          items: s.items
            .map((i: SubscriptionItem) => {
              return <SubscriptionUIItem>{
                itemId: i.id,
                itemPriceProductName: i.price.product.name,
                itemPriceUnit_amount: calculateUnitPriceFromDiscount(coupon, i),
                itemPriceCurrency: i.price.currency,
                itemPlanInterval: i.plan.interval,
                itemPriceProductMetadataAccountRole:
                  i.price.product.metadata.accountRole
              };
            })
        };
        console.log(result);
        return result;
      }

      return a;
    }, null);
  }
);

export const selectSubscriptionId = createSelector(
  selectActiveSubscriptionsUIDisplay,
  (subs: SubscriptionUIdisplay | null) => {
    if (subs) {
      return subs.subscriptionID;
    }

    return null;
  }
);

export interface CheckHasActiveSubscription {
  hasActiveSubscription: boolean;
  isRetrievedFromFirestore: boolean;
}

export const selectIsRetrievedFromFirestoreHasSubscription = createSelector(
  selectHasActiveSubscription,
  selectSubscriptionsIsRetrievedFromFirestore,
  (
    hasActiveSubscription: boolean,
    isRetrievedFromFirestore: boolean
  ): CheckHasActiveSubscription => {
    return {
      hasActiveSubscription,
      isRetrievedFromFirestore
    };
  }
);

export const selectIsRetrievedFromFirestoreHasSubscription$ = pipe(
  select(selectIsRetrievedFromFirestoreHasSubscription),
  filter((r: CheckHasActiveSubscription) => r.isRetrievedFromFirestore),
  distinctUntilJsonChanged()
);

export const selectSubscriptionStorage = createSelector(
  selectActiveSubscriptions,
  (subs: Subscription[]): number => {
    // [0].items[0].price.storageProduct.metadata.baseStorageGB

    return subs.reduce((a: number, s: Subscription) => {
      const total = s.items.reduce((_a: number, p: SubscriptionItem) => {
        if (
          p.price?.product?.metadata?.baseStorageGB !== 'null' &&
          p.price?.product?.metadata?.baseStorageGB !== null
        ) {
          return Math.max(
            _a,
            parseInt(<string>p.price.product.metadata.baseStorageGB, 10)
          );
        }

        return _a;
      }, 0);

      return Math.max(a, total);
    }, 0);
  }
);
