import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy
} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {LetModule, PushModule} from '@ngrx/component';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {
  CustomFirestoreService,
  DataAccessStudioSiteStoreModule,
  DeviceDetectionService,
  GA_CONSOLE_LOG_EVENTS,
  StripePaymentSelectionService
} from '@spout/global-web/data-access';
import {
  DEFAULT_PERFECT_SCROLLBAR_CONFIG,
  ENDPOINTS,
  ENVIRONMENT,
  FIREBASE_CONFIG,
  LOGOUT_REDIRECT
} from '@spout/global-web/models';
import {SocialModule} from '@spout/global-web/ui';
import {TooltipModule} from 'ng2-tooltip-directive';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {StudioSiteRoutingModule} from './studio-site-routing.module';

// firebase
//   .firestore()
//   .enablePersistence({synchronizeTabs: true})
//   .then(() => {
//     /* noop */
//   });

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    LetModule,
    PushModule,
    PerfectScrollbarModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatTabsModule,
    MatSidenavModule,

    RouterModule,

    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled:
        environment.serviceWorker && !window.location.href.includes('localhost')
    }),
    MatListModule,
    DataAccessStudioSiteStoreModule,
    StudioSiteRoutingModule,
    SocialModule,
    TooltipModule
  ],
  providers: [
    StripePaymentSelectionService,
    DeviceDetectionService,
    CustomFirestoreService,
    Location,
    {provide: LocationStrategy, useClass: PathLocationStrategy},

    {
      provide: GA_CONSOLE_LOG_EVENTS,
      useValue: !environment.production
    },
    {provide: LOGOUT_REDIRECT, useValue: ''},
    {provide: FIREBASE_CONFIG, useValue: environment.firebase},
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    {
      provide: ENDPOINTS,
      useValue: environment.endpoints
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
