
export class FirebaseConfig {
	projectId:string;
	apiKey:string;
	authDomain:string;
	databaseURL:string;
	storageBucket:string;
	appId:string;
	messagingSenderId:string;
	measurementId:string

	constructor(
		projectId:string,
		apiKey:string,
		authDomain:string,
		databaseURL:string,
		storageBucket:string,
		appId:string,
		messagingSenderId:string,
		measurementId:string,
	) {
	this.projectId = projectId;
	this.apiKey = apiKey;
	this.authDomain = authDomain;
	this.databaseURL = databaseURL;
	this.storageBucket = storageBucket;
	this.appId = appId;
	this.messagingSenderId = messagingSenderId;
	this.measurementId = measurementId;
	}
}

let firebaseDevStudioAppConfig: FirebaseConfig = {
  apiKey: "AIzaSyBsAn7U9swWk0zcQ4ee28J2U0ByxzEZ8ik",

  authDomain: "spout-dev-d0c97.firebaseapp.com",

  databaseURL: "https://spout-dev-d0c97-default-rtdb.firebaseio.com",

  projectId: "spout-dev-d0c97",

  storageBucket: "spout-dev-d0c97.appspot.com",

  messagingSenderId: "898469819067",

  appId: "1:898469819067:web:ba3650e1a44a23f77e6f9e",

  measurementId: "G-1EDGMPPS8J"


};

let firebaseDevMarketingConfig: FirebaseConfig = {
 apiKey: "AIzaSyBsAn7U9swWk0zcQ4ee28J2U0ByxzEZ8ik",

  authDomain: "spout-dev-d0c97.firebaseapp.com",

  databaseURL: "https://spout-dev-d0c97-default-rtdb.firebaseio.com",

  projectId: "spout-dev-d0c97",

  storageBucket: "spout-dev-d0c97.appspot.com",

  messagingSenderId: "898469819067",

  appId: "1:898469819067:web:a5624ad47ef5a2347e6f9e",

  measurementId: "G-8H64XW4PCB"


};

export  {firebaseDevStudioAppConfig, firebaseDevMarketingConfig};

