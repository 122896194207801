import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {CurrentIds, Musician} from '@spout/global-any/models';
import {
  createPassThroughFeatureSelector,
  createPassThroughSelector,
  distinctUntilJsonChanged,
  isCreatedByLoggedInMusician
} from '@spout/global-web/fns';
import {
  ACCOUNT_FEATURE_KEY,
  AccountState,
  AuthError
} from '@spout/global-web/models';
import {allValuesHasValue, hasValue} from '@uiux/fn';
import {hasValuePipe} from '@uiux/rxjs';
import {pipe} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {accountIsLoaded, accountIsOnboarded} from './account.fns';

// Lookup the 'Account' feature state managed by NgRx
export const selectAccountState =
  createFeatureSelector<AccountState>(ACCOUNT_FEATURE_KEY);

export const selectAuthAccountState_passThrough =
  createPassThroughFeatureSelector<AccountState>(ACCOUNT_FEATURE_KEY);

export const selectAccountState_passThrough = createPassThroughSelector(
  selectAuthAccountState_passThrough,
  (state): AccountState => state
);
// Should return only one time
// null if not defauld ids in firestore account
export const selectCurrentIdsFromAccountDefaultIds$ = pipe(
  select(selectAccountState),
  filter(account => hasValue(account.email)),
  map(
    ({
      defaultMixId,
      defaultSongId,
      defaultTrackId,
      defaultProjectId
    }: AccountState) => {
      if (defaultProjectId && defaultSongId && defaultMixId && defaultTrackId) {
        const defaultIDs: CurrentIds = {
          currentProjectId: defaultProjectId,
          currentSongId: defaultSongId,
          currentTrackId: defaultTrackId,
          currentMixId: defaultMixId
        };

        // console.log('CURRENT IDS FROM PIPE', defaultIDs, allValuesHasValue(defaultIDs));

        if (allValuesHasValue(defaultIDs)) {
          return defaultIDs;
        }

        return {
          currentProjectId: null,
          currentSongId: null,
          currentTrackId: null,
          currentMixId: null
        };
      }

      return {
        currentProjectId: null,
        currentSongId: null,
        currentTrackId: null,
        currentMixId: null
      };
    }
  )
);

export const selectDisplayName = createSelector(
  selectAccountState,
  (state: AccountState) => state.displayName
);

export const selectEmail = createSelector(
  selectAccountState,
  (state: AccountState): string => {
    if (state && state.email) {
      return state.email;
    }

    return '';
  }
);

export const selectPhoneNumber = createSelector(
  selectAccountState,
  (state: AccountState) => state.phoneNumber
);

export const selectPhotoUrl = createSelector(
  selectAccountState,
  (state: AccountState) => state.photoURL
);

export const selectProviderId = createSelector(
  selectAccountState,
  (state: AccountState) => state.providerId
);

export const selectStageName = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return state.stageName;
  }
);

export const selectUserName = createSelector(
  selectAccountState,
  (state: AccountState) => {
    if (state?.stageName?.length) {
      return state.stageName;
    } else if (state?.displayName?.length) {
      return state.displayName;
    } else if (state.email?.length) {
      return state.email;
    } else {
      return '';
    }
  }
);

export const selectUserInitial = createSelector(
  selectUserName,
  (name: string) => (name.length ? name.slice(0, 1).toUpperCase() : '')
);

export const getStageName$ = pipe(
  select(selectUserName),
  distinctUntilChanged()
);

export const selectUid = createSelector(
  selectAccountState,
  (state: AccountState): string => {
    {
      if (state && state.uid) {
        return state.uid;
      }

      return '';
    }
  }
);

/**
 * @deprecated
 */
export const selectUid_passThrough = createSelector(
  selectAccountState,
  (state: AccountState): string => {
    {
      if (state && state.uid) {
        return state.uid;
      }

      return '';
    }
  }
);

export const selectAccountIsLoaded = createSelector(
  selectAccountState,
  (state: AccountState): boolean => state.isRetrievedFromFirestore
);

export const selectCreatedAt = createSelector(
  selectAccountState,
  (account: AccountState) => account.createdAt
);

export const selectAccountLoadedAndCreateAt = pipe(
  select(selectAccountState),
  filter((account: AccountState) => account.isRetrievedFromFirestore),
  distinctUntilChanged(),
  map((account: AccountState) => account.createdAt)
);

export interface CheckIfAccountIsOnboarded {
  isLoggedIn: boolean;
  isRetrievedFromFirestore: boolean;
  accountIsOnboarded: boolean;
}

export const selectIsOnboarded = createSelector(
  selectAccountState,
  (a: AccountState): CheckIfAccountIsOnboarded => {
    return {
      isLoggedIn: a.isLoggedIn,
      isRetrievedFromFirestore: a.isRetrievedFromFirestore,
      accountIsOnboarded: accountIsOnboarded(a)
    };
  }
);

export const selectIsOnboarded$ = pipe(
  select(selectIsOnboarded),
  filter(
    (c: CheckIfAccountIsOnboarded) => c.isRetrievedFromFirestore && c.isLoggedIn
  ),
  distinctUntilJsonChanged()
);

export const selectStageNameOnboarded = createSelector(
  selectAccountState,
  (state: AccountState): boolean =>
    state.stageName !== null && state.stageName.length > 0
);

export const selectGenresOnboarded = createSelector(
  selectAccountState,
  onboarding => onboarding.hasGenres
);

export const selectGenresNotOnboarded = createSelector(
  selectAccountState,
  onboarding => !onboarding.hasGenres
);

export const selectInstrumentsOnboarded = createSelector(
  selectAccountState,
  onboarding => onboarding.hasInstruments
);

export const selectInstrumentsNotOnboarded = createSelector(
  selectAccountState,
  onboarding => !onboarding.hasInstruments
);

export const getIsCreatedByLoggedInMusicianFn_passThrough = (config: {
  createdBy: Musician;
}) =>
  createPassThroughSelector(selectUid, (uid: string | null): boolean =>
    isCreatedByLoggedInMusician(uid, config)
  );

export const getIsCreatedByLoggedInMusicianFn = (config: {
  createdBy: Musician;
}) =>
  createSelector(selectUid, (uid: string | null): boolean =>
    isCreatedByLoggedInMusician(uid, config)
  );

export const getError = createSelector(
  selectAccountState,
  (state: AccountState) => state.authError
);

export const getAlertMessage = createSelector(
  getError,
  (authError: AuthError | null) => {
    return authError && authError.message;
  }
);

export const getIsLoggedIn = createSelector(
  selectAccountState,
  (state: AccountState): boolean => {
    return state.isLoggedIn;
  }
);
export const getIsSubscribed = createSelector(
  selectAccountState,
  (state: AccountState): boolean => {
  console.log("getIsSubscribed")
  console.log(state);
    return state.isRetrievedFromFirestore &&  state.subscribedAt != null;
  }
);
export const getSubscribedAt = createSelector(
  selectAccountState,
  (state: AccountState): (number|null) => {
    return state.subscribedAt;
  }
);export const getState = createSelector(
  selectAccountState,
  (state: AccountState): (AccountState|null) => {
    return state;
  }
);
export const selectIsLoggedIn$ = pipe(
  select(getIsLoggedIn),
  // tap((isLoggedIn) => {
  //   console.log(isLoggedIn);
  // }),
  distinctUntilChanged<boolean>()
);
export const selectIsSubscribed$ = pipe(
  select(getIsSubscribed),
  // tap((isLoggedIn) => {
  //   console.log(isLoggedIn);
  // }),
  distinctUntilChanged<boolean>()
);

export const geIsLoggedInIsTrue = pipe(
  select(getIsLoggedIn),
  distinctUntilChanged<boolean>(),
  map((isLoggedIn: boolean) => isLoggedIn)
);

export const selectIsUserAuthenticated = createSelector(
  selectAccountState,
  accountIsLoaded
);

export const geIsLoggedInIsFalse = pipe(
  select(getIsLoggedIn),
  distinctUntilChanged<boolean>(),
  map((isLoggedIn: boolean) => !isLoggedIn)
);

export const getIsLoggedOut = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return !state.isLoggedIn;
  }
);

export const selectIsLoggedOut = pipe(
  select(getIsLoggedOut),
  distinctUntilChanged()
);

export const selectUidHash = createSelector(
  selectAccountState,
  state => state.uidHash
);

export const selectUidHash$ = pipe(
  select(selectUidHash),
  hasValuePipe<string | null, string>(),
  distinctUntilChanged<string>()
);

export const selectSubscribedAtFromAccount = createSelector(
  selectAccountState,
  (a: AccountState) => a.subscribedAt
);

export interface CheckHasSubscribed {
  isRetrievedFromFirestore: boolean;
  subscribedAt: number | null;
}

export const selectIsRetrievedFromFirestoreSubscribedAtFromAccount =
  createSelector(
    selectSubscribedAtFromAccount,
    selectAccountIsLoaded,
    (
      subscribedAt: number | null,
      isRetrievedFromFirestore: boolean
    ): CheckHasSubscribed => {
      return {
        isRetrievedFromFirestore,
        subscribedAt
      };
    }
  );

export const selectSubscribedAtFromAccount$ = pipe(
  select(selectIsRetrievedFromFirestoreSubscribedAtFromAccount),
  filter((d: CheckHasSubscribed) => d.isRetrievedFromFirestore),
  distinctUntilJsonChanged<CheckHasSubscribed>()
);

// export const getIsOnline = createSelector(getUser, (state: AuthState) => state.isOnline);

// export const selectEmail = createSelector(getUser, (state: AuthState) => state.email);
