import {
  algoliaConfigDev,
  firebaseDevMarketingConfig,
  stripePublishableKeyDev
} from '@spout/global-any/secrets';
import {defaultStripePaymentsOptions} from '@spout/global-web/data-access';
import {endpointsStaging, IEnvironmentState} from '@spout/global-web/models';

export const environment: IEnvironmentState = {
  production: false,
  mobileTest: false,
  appName: 'dev-marketing',
  facebookAppId: '2219988261549213',
  firebaseProjectID: 'spout-dev-d0c97',
  firebase: firebaseDevMarketingConfig,
  stripePublishableKey: stripePublishableKeyDev,
  stripePaymentOptions: defaultStripePaymentsOptions,
  algolia: algoliaConfigDev,
  serviceWorker: true,
  endpoints: endpointsStaging,
  emulator: false,
  authSite: 'https://dev.auth.spoutstudio.io'
};

// faceBookInit(environment.facebookAppId);
