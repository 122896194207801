/*
spt-login is used in a number of places in this project.


Subscription step "Create Account" | This will get skipped if user is logged in.
libs\global-web\feature-page-subscribe\src\lib\payment-wizard-small\payment-wizard-small.component.html
libs\global-web\feature-page-subscribe\src\lib\payment-wizard-wide\payment-wizard-wide.component.html


libs\studio-web\feature-page-firebase-login\src\lib\login-browser\login-firebase\login-firebase.component.html

libs\marketing\feature-page-login\src\lib\studio-site-feature-page-login.component.html

 */

import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {
  logout,
  CustomFirestoreService,
  StripePaymentSelectionService
} from '@spout/global-web/data-access';
import {AccountState, EmailPassword} from '@spout/global-web/models';
import {getIsLoggedIn, selectAccountState} from '@spout/global-web/data-access';
import {SvgLoaderService} from '@spout/global-web/utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AlertMessageContainerComponent} from '../alert-message/alert-message-container.component';
import {ALERT_MESSAGE_ID} from '../alert-message/alert-message-store.service';
import {
  animationDelay,
  animationDuration
} from '../animations/animation.constants';
import {LOGIN_CONFIG} from './login.config';
import {LOGIN_KEY} from './login.model';
import {MyErrorStateMatcher} from './logn.validators';
import {
  UserCredential,
  User,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider
} from '@firebase/auth';

@Component({
  selector: 'spt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showMessage', [
      state('false', style({opacity: 0})),
      state('true', style({opacity: 1})),
      transition('false => true', [
        animate(`${animationDuration} ${animationDelay} ease-out`)
      ]),
      transition('true => false', [animate(`${animationDuration} ease-in`)])
    ])
  ]
})
export class LoginComponent {
  private loginStorageState: string | null;
  promo: string = 'GETSPOUTNOW';
  redirectPath: string[] = ['subscribe'];
  showProgress$: BehaviorSubject<boolean>;
  showUi$: BehaviorSubject<boolean>;
  forgotPassword = false;
  wantsPromo: boolean = false;
  selected = new UntypedFormControl(0);
  matcher = new MyErrorStateMatcher();
  config = LOGIN_CONFIG;
  ALERT_MESSAGE_ID = ALERT_MESSAGE_ID;
  isLoggedIn$: Observable<boolean>;
  notLoggedIn$: Observable<boolean>;
  account$: Observable<AccountState>;
  @Input() signInWithPopup = true;
  @Output() authEmailUser: EventEmitter<User | null> = new EventEmitter();

  @HostBinding('class.login-component') bind = true;

  @ViewChild('alertMessageContainerComponent')
  alertMessageContainerComponent!: AlertMessageContainerComponent;
  @ViewChild('alertMessageContainerComponentForgotPassword')
  alertMessageContainerComponentForgotPassword!: AlertMessageContainerComponent;
  @ViewChild('alertMessageContainerComponentSignUp')
  alertMessageContainerComponentSignUp!: AlertMessageContainerComponent;

  constructor(
    private sptFirebase: CustomFirestoreService,
    private route: ActivatedRoute,
    public payment: StripePaymentSelectionService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private svgLoader: SvgLoaderService,
    private store: Store
  ) {
    this.showProgress$ = new BehaviorSubject<boolean>(false);
    this.loginStorageState = localStorage.getItem(LOGIN_KEY);
    this.showUi$ = new BehaviorSubject<boolean>(false);
    this.svgLoader.addSvgIconSet();
    this.isLoggedIn$ = this.store.pipe(select(getIsLoggedIn));
    this.notLoggedIn$ = this.isLoggedIn$.pipe(
      map((isLoggedIn: boolean) => !isLoggedIn)
    );
    this.account$ = this.store.pipe(select(selectAccountState));
    var signup = this.router.url == '/signup-with-promo';

    if (signup) {
      this.selected.setValue(1, {emitEvent: false});
    }
  }

  setTabIndex(index: number) {
    this.selected.setValue(index, {emitEvent: false});
  }

  enableForgotPassword() {
    this.forgotPassword = true;
    this.selected.setValue(2);
    this.cd.detectChanges();
  }

  signInWithGoogle(isSigningIn:boolean) {
    const googleAuthProvider = new GoogleAuthProvider();

    googleAuthProvider.setCustomParameters({
      prompt: 'select_account'
    });

    if (this.signInWithPopup) {
      this.sptFirebase
        .signInWithPopup(googleAuthProvider)
        .then((userCredential: UserCredential) => {
          if(isSigningIn){
            this.onLogIn(userCredential.user);
          
          }
          else{
            this.onSignUp(userCredential.user, false);
          }
        });
    } else {
      this.sptFirebase.signInWithRedirect(googleAuthProvider).then(() => {
      });
    }
  }

  signInWithFacebook() {
    const facebookAuthProvider = new FacebookAuthProvider();

    if (this.signInWithPopup) {
      this.sptFirebase
        .signInWithPopup(facebookAuthProvider)
        .then((userCredential: UserCredential) => {
          this.onLogIn(userCredential.user);
        });
    } else {
      this.sptFirebase.signInWithRedirect(facebookAuthProvider).then(() => {
      });
    }
  }

  signInWithTwitter() {
    const twitterAuthProvider = new TwitterAuthProvider();

    if (this.signInWithPopup) {
      this.sptFirebase
        .signInWithPopup(twitterAuthProvider)
        .then((userCredential: UserCredential) => {
          this.onLogIn(userCredential.user);
        });
    } else {
      this.sptFirebase.signInWithRedirect(twitterAuthProvider).then(() => {

      });
    }
  }

  signInWithEmail(c: EmailPassword) {
    this.sptFirebase
      .signInWithEmailAndPassword(c.email, c.password)
      .then((userCredential: UserCredential) => {
        this.onLogIn(userCredential.user);
      })
      .catch(error => {
        if (error.message && error.message.length) {
          this.alertMessageContainerComponent.addWarnMessage({
            id: ALERT_MESSAGE_ID.PASSWORD_AUTH,
            message: error.message
          });
        }
      });
  }

  createUserWithEmailAndPassword(c: EmailPassword) {
    this.sptFirebase
      .createUserWithEmailAndPassword(c.email, c.password)
      .then((userCredential: UserCredential) => {

        this.onSignUp(userCredential.user, c.open_promo); //todo
      })
      .catch((error: any) => {
        this.alertMessageContainerComponentSignUp.addWarnMessage({
          id: ALERT_MESSAGE_ID.CREATE_PASSWORD,
          message: error.message
        });
      });
  }

  sendEmailPasswordReset(email: string) {
    this.sptFirebase
      .sendPasswordResetEmail(email)
      .then(() => {
        // Email sent.
        this.alertMessageContainerComponentForgotPassword.addSuccessMessage({
          id: ALERT_MESSAGE_ID.RESET_EMAIL_SENT,
          message: 'Check your email for reset instructions.'
        });
      })
      .catch(error => {
        this.alertMessageContainerComponentForgotPassword.addWarnMessage({
          id: ALERT_MESSAGE_ID.EMAIL_RESET_PASSWORD,
          message: error.message
        });
      });
  }

  onSignUp(emit: User, openPromo: boolean) {
    console.log('onSignUp | ' + this.redirectPath);
    this.authEmailUser.emit(emit);

    this.router
      .navigate(['checkout'], {
        queryParams: {s: '1', openPromo: openPromo}
      })
      .then(() => {
        console.log('onSignUp | ' + this.redirectPath + ' | DONE');
      });
  }

  onLogIn(emit: User) {
    console.log('onLogIn | ' + this.redirectPath);
    this.authEmailUser.emit(emit);
    this.router.navigate(['home']).then(() => {
      console.log('onLogIn | HOME | DONE');
    });
  }


  logout() {
    this.store.dispatch(logout());
  }
}
